<template>
    <div class="orders">
      <div class="row">
        <div class="col">
          <div class="card">
            <div class="card-body">
              <h4 class="box-title">Porcentaje de asistencia por curso </h4>
            </div>
            <div class="card-body--">
              <div class="row">
                <div class="col-lg-12">
                    <div class="row" >
                      <div class="col-lg-4" v-for="(item, index) in asistencia_course" :key="index">
                        <div class="card-body">
                          <div :class="'progress-box progress-' + index">
                            <h4 class="por-title">{{item.course_name}}</h4>
                            <div class="por-txt">
                            {{item.total}}  ({{item.porcentaje_asistencia}} %)</div>
                            <div class="progress mb-2" style="height: 5px;">
                              <div
                              :class="'progress-bar bg-flat-color-' + index"
                              role="progressbar"
                              :style="'width:' + item.porcentaje_asistencia + '%;'"
                              aria-valuenow="25"
                              aria-valuemin="0"
                              aria-valuemax="100"></div>
                            </div>
                          </div>
                      </div>
                    </div> <!-- /.row -->
                  </div>
                </div><!-- /# column -->
              </div>
            </div>
          </div> <!-- /.card -->
        </div>  <!-- /.col-lg-8 -->
        </div>
    </div>
</template>
<script>

/* eslint-disable */
export default {
  name: 'porcentaje_asistencia',
  created(){
    this.list();
  },
  data() {
    return {
      asistencia_course: [],
      url: 'percent_assistant/'
    };
  },
  methods: {
    list() {
      const payload = {};
      const self = this;
      this.sendRequest(payload).then((data) => {
        self.asistencia_course = data.data;            
      }).catch((error) => {
        console.log(error);
        self.$swal.fire('Error!', 'No fue posible obtener los datos.', 'error');
      });
      return null;
    },
    async sendRequest(payload) {
      const result = await this.$http.get(this.url, payload);
      return result;
    },
  },
  filters: {
    status_to_text(value){
      if (value) return 'Sí';
      return 'No';
    }
  },
};

</script>
<style lang="css" scoped>
    #weatherWidget .currentDesc {
        color: #ffffff!important;
    }
    .traffic-chart {
        min-height: 335px;
    }
    #flotPie1  {
        height: 150px;
    }
    #flotPie1 td {
        padding:3px;
    }
    #flotPie1 table {
        top: 20px!important;
        right: -10px!important;
    }
    .chart-container {
        display: table;
        min-width: 270px ;
        text-align: left;
        padding-top: 10px;
        padding-bottom: 10px;
    }
    #flotLine5  {
          height: 105px;
    }

    #flotBarChart {
        height: 150px;
    }
    #cellPaiChart{
        height: 160px;
    }
    .on-over {
      cursor: pointer;
    }
</style>
